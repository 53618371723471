import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ContentGuard',
  setup: (_, props) => {
    if (process.env.CONTENT_MODERATION !== 'true')
      return () => props.slots.default?.()

    return () => null
  },
})
